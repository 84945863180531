
const env={
    API_URL:'https://localhost:44369',
    CORS_URL:'http://localhost:3000',
    PREVIEW:'https://localhost:44319'
}
//Live
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL="https://service.cms.elvethamhotel.co.uk";
//     env.CORS_URL="https://site-manager.elvethamhotel.co.uk";
//     env.PREVIEW='https://elvetham.hostack.com'
// }
if (process.env.NODE_ENV === 'production') {
    env.API_URL="https://c1.cms.service.hostack.com";
    env.CORS_URL="https://ecms.hostack.com";
    env.PREVIEW='https://elvetham-test.hostack.com'
}
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL="https://service1.xeniaone.com";
//     env.CORS_URL="https://app1.xeniaone.com";
//     env.PREVIEW='https://service2.xeniaone.com'
// }
export {env as ServiceUrls};